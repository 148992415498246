import { sizedArticleUrl } from 'app/utils'
import { getModuleConfigValue } from 'components/email-layout/utils'
import { Fade } from 'components/fade'
import { isTruthy } from 'generic/utility'
import React from 'react'
import { strfFormatDate } from 'shared_server_client/dates'
import { SectionConfig, TemplateModule } from 'shared_server_client/types/email_layout'
import * as CommonUtils from 'shared_server_client/utils'
import { Article, EmailAttributes, generalArticleAltText, headerAltText, headerLogoUrl, logoSizeAsNumber, ViewInBrowserFilter } from '../../constants'
import * as BaseLayout from '../base_layout'
import './styles.scss'

const defaultArticles = [{}, {}, {}, {}, {}, {}]

export class BoldLayout extends BaseLayout.BaseLayoutComponent {
  protected templateClassName: string = 'bold-layout'

  protected headerModule = () => {
    const { previewDate } = this.props
    const displayDate = !previewDate ? new Date() : previewDate
    return <div className="template-header" style={{
        backgroundColor: isTruthy(this.getAttribute(EmailAttributes.hideHeaderBackground)) ||
        this.getAttribute(EmailAttributes.primaryColor)}}>
      {this.getAttribute(EmailAttributes.viewInBrowser) &&
          this.getAttribute(EmailAttributes.viewInBrowser) === ViewInBrowserFilter.TOP &&
            this.renderViewInBrowserAtTop(EmailAttributes.secondaryColor)
      }
      <div className="date-and-subscribe text-uppercase">
        <div className="date">
          <p style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
          {strfFormatDate(displayDate, this.getAttribute(EmailAttributes.headerDateFormat))}</p>
        </div>
        <div className="subscription">
          {(this.getAttribute(EmailAttributes.subscribeUrl) &&
          isTruthy(this.getAttribute(EmailAttributes.showSubscribeUrlInHeader))) &&
          <a href="#">
            <p className="subscribe-link" style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
                {this.getAttribute(EmailAttributes.subscribeUrlText)}
            </p>
          </a>}
          {(this.getAttribute(EmailAttributes.subscribeUrl) &&
          isTruthy(this.getAttribute(EmailAttributes.showUnsubscribeUrlInHeader))) &&
          <a href="#">
            <p className="unsubscribe-link" style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
                {this.getAttribute(EmailAttributes.unsubscribeUrlText)}
            </p>
          </a>}
        </div>

      </div>
      {this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-image-wrapper-container">
        <div className="template-header-image-wrapper">
          <a href={this.props.session.brand_header_url}
            target="_blank"
            rel="noopener">
            <img className="template-header-image"
                  src={headerLogoUrl(this.props.session)}
                  alt={headerAltText(this.props.session)}
                  style={{height: `${logoSizeAsNumber(this.props.session)}px`}}/>
          </a>
        </div>
      </div>}
      {!this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-text-wrapper-container">
        <div className="template-header-text-wrapper"
            style={{width: 'auto', height: 'auto'}}>
          <div className="template-header-text"
            style={{fontSize: this.getFontSize()}}>
            {this.getAttribute(EmailAttributes.companyName)}
          </div>
        </div>
      </div>}
    </div>
  }

  protected sectionModule = (module: TemplateModule) => {
    const articles = this.getSectionArticles(module)
    const section = this.getSectionDetails(module)
    //section will not be empty
    if ((articles && articles.length > 0) || section) {
      return <div className="article-wrapper">
        {isTruthy(getModuleConfigValue(module, SectionConfig.SECTION_SHOW_HEADER, 'true')) &&
        <div className="section-title-wrapper">
          <span className="section-title" style={this.sectionTitleStyles(module)}>
            {section ?
             section.name : ''
            }
          </span>
        </div>}
        {articles && articles.length > 0 ?
            this.renderArticles(articles, module)
          :
          <div className="no-article-section-module">
            There are no articles currently available for this section
          </div>
        }
      </div>
      } else {
        return null
      }
  }

  protected articleModule = (module: TemplateModule) => {
    if (!this.props.isLoading && this.props.content.articles.length === 0) {
      return <span className="no-content-message">You need more content for your next newsletter!</span>
    } else {
      return this.renderArticles(this.props.content.articles || defaultArticles, module)
    }
  }

  protected renderArticles = (articles: Article[], module: TemplateModule) => {
    if (articles && articles.length) {
      return <div className="article-module">
        {articles.slice(module.moduleConfig.start, module.moduleConfig.end || -1)
                 .map((article, i) => (
          <ArticleHtml
            key={i}
            showImage={this.showImage}
            cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle}
            {...this.props}
            {...article}
          />
      ))}
      </div>
    } else {
      return null
    }
  }

  protected restOfArticlesModule = (module: TemplateModule) => {
    const restOfArticles: any[] = (this.props.content.articles || defaultArticles).slice(module.moduleConfig.start)
    if (restOfArticles.length) {
      return <div className="rest-of-articles-module">
        {restOfArticles.map((article, i) =>
          <ArticleHtml
            key={i}
            showImage={this.showImage}
            cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle}
            {...this.props}
            {...article}
          />,
        )}
      </div>
    } else {
      return null
    }
  }

  protected footerStyle = () => {
    return CommonUtils.footerStyles(this.props.session)
  }

  private buildStyle = (key: string) => {
    const styleObject = {
      fontFamily: this.getAttribute(`${EmailAttributes.newsletterFontFamily}`) || 'Arial',
      fontSize: this.getAttribute(`${key}_${EmailAttributes.fontSize}`),
      fontWeight: this.getAttribute(`${key}_${EmailAttributes.fontWeight}`),
      color:  this.getAttribute(`${key}_${EmailAttributes.fontColor}`),
      border: '',
    }
    if (key === 'source') {
      styleObject.border = this.getAttribute(`title_${EmailAttributes.fontColor}`)
    }
    return styleObject
  }
}

const ArticleHtml = (props: any) => <Fade>
    <div className="big-bold-article">
      {props.showImage(props, 425, 567) &&
      <a href={props.url}
          target="_blank"
          rel="noopener">
        <div className="article-image">
          <img src={sizedArticleUrl(props, 425, 567)}
               alt={generalArticleAltText(props.session)} width="567" height="425" />
        </div>
      </a>}
      <div>
        <a href={props.url}
            target="_blank"
            rel="noopener"
            className="article-title" style={props.buildStyle('title')}>
          {props.title}
        </a>
      </div>
      <div className="article-content" style={props.buildStyle('description')}>
        <span dangerouslySetInnerHTML={{__html: props.cappedDescription(props.description)}}></span>
      </div>
      <div className="source-title source-box" style={props.buildStyle('source')}>
        <a href={props.url}
            style={props.buildStyle('source')}
            target="_blank"
            rel="noopener">
          {props.site_name || props.source_name}
        </a>
      </div>
    </div>
</Fade>
