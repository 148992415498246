import { flatMap, isNil, uniq } from 'lodash'
import * as React from 'react'

import { EmailAttributes } from 'shared_server_client/constants'

import { DropdownOption } from 'components/dropdown/component'
import { MenuBrush } from 'components/icons/menu-brush'
import { MenuFooter} from 'components/icons/menu-footer'
import { HeaderLogo } from 'components/icons/menu-header-logo'
import { MenuImagesText } from 'components/icons/menu-images-text'
import { strfFormatDate } from 'shared_server_client/dates'
import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import { CapTextStrategy } from 'shared_server_client/utils'
import { MenuArticleSettings } from '../icons/menu-article-settings'

export {
  EmailAttributes,
  leadTextDefaults,
} from 'shared_server_client/constants'

export type EmailLayoutData = any

export type Article = any

export type Image = any

export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active communityInfo doesn\'t found'
export const IMAGE_ALT_TEXT = 'article image'
export const MAX_EMAILS_ALLOWED_FOR_SEND = 5
export const MAX_TIME_DELAY_FOR_EMAIL_SEND = 20
export const RETRY_LIMIT = 2

export const RETRY_WAIT_TIME = 5000

export const STRIPO_TEMPLATE_DEFAULT_NAME = "my_stripo_template"

export interface Text {
  community_id: number
  content_type: string
  description: string
  end_date: string
  id: number
  is_active: boolean
  is_edited: boolean
  is_scheduled: boolean
  lead_text_type: string
  start_date: string
  styles: string
  typeId: string
}

export enum MenuSections {
  layout = 'Layout',
  articleSettings = 'Article Settings',
  fontAndColor = 'Font & Color',
  headerAndLogo = 'Header',
  footer = 'Footer',
  leadTextEditor = 'Lead Text Editor',
  leadText2Editor = 'Lead Text 2 Editor',
  uploadImage = 'Upload Image',
  sectionEditor = 'Section Editor',
}

export const UpgradeTemplateField: string = 'isUpgradeTemplateShowing'

export enum UpgradeTemplateFieldVal {
  customTemplate = 'customTemplate',
  layout = 'layout',
  contactToRasa = 'contactToRasa',
}

export const MenuIcons = {
  [MenuSections.fontAndColor]: <MenuBrush />,
  [MenuSections.headerAndLogo]: <HeaderLogo />,
  [MenuSections.footer]: <MenuFooter />,
  [MenuSections.layout]: <MenuImagesText />,
  [MenuSections.articleSettings]: <MenuArticleSettings />,
  [MenuSections.leadTextEditor]: '',
  [MenuSections.leadText2Editor]: '',
  [MenuSections.uploadImage]: '',
}

const emailAttributeDefaults: any = {}
emailAttributeDefaults[EmailAttributes.logoSize] = '150px'
emailAttributeDefaults[EmailAttributes.maxArticles] = 6

export const getAttribute = (props: any, key: string, defaultValue: any = null): any => {
  if ( isNil(props[key]) ) {
    return emailAttributeDefaults[key] || defaultValue
  } else {
    return props[key]
  }
}

export const logoSizeAsNumber = (props) => {
  const logoSize = getAttribute(props, EmailAttributes.logoSize, emailAttributeDefaults[EmailAttributes.logoSize])
  return logoSize.replace('px', '')
}

export const headerLogoUrl = (props: any) => getAttribute(props, EmailAttributes.brandHeaderImageUrl)
export const headerAltText = (props: any) => getAttribute(props, EmailAttributes.brandHeaderAltText)
export const generalArticleAltText = (props: any) => getAttribute(props, EmailAttributes.articleAltText) || IMAGE_ALT_TEXT

export const FONT_SIZES = [
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
]

export const BORDER_FONT_SIZES = [
  '0px',
  '1px',
  '2px',
  '4px',
  '8px',
  '16px',
]

export const FONT_WEIGHTS = [
  'bold',
  'normal',
]

export interface FontFamily {
  category?: string,
  hide?: boolean,
  name: string,
  value: string,
}

//https://help.campaignmonitor.com/email-builder-font-compatibility
export const FONT_FAMILIES: FontFamily[] = [
  {
    category: 'header',
    name: 'Email Safe',
    value: '',
  },
  {
    name: 'Arial',
    value: 'Arial, sans-serif',
  },
  {
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    name: 'Geneva',
    value: 'Geneva, Calibri, sans-serif',
  },
  {
    name: 'Helvetica Neue',
    value: 'Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  {
    name: 'Lucida',
    value: 'Lucida, Lucida Sans, Lucida Sans Unicode, Verdana, sans-serif',
  },
  {
    name: 'Montserrat',
    value: 'Montserrat, DejaVu Sans, Verdana, sans-serif',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, Geneva, sans-serif',
  },
  {
    name: 'Trebuchet MS',
    value: 'Trebuchet MS, Lucida Grande, Lucida Sans Unicode, sans-serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    category: 'divider',
    name: '',
    value: '',
  },
  {
    category: 'header',
    name: 'Custom',
    value: '',
  },
  {
    name: 'Arvo',
    value: 'Arvo, Museo Slab, Rockwell, Verdana, serif',
  },
  {
    name: 'Lato',
    value: 'Lato, Nunito, Segoe UI, Trebuchet MS, sans-serif',
  },
  {
    name: 'Open sans',
    value: 'Open sans, Segoe UI, Lucida Grande, Lucida Sans Unicode, sans-serif',
  },
  {
    name: 'Oswald',
    value: 'Oswald, Arial Narrow, MS UI Gothic, Verdana, sans-serif',
  },
  {
    name: 'Raleway',
    value: 'Raleway, Trebuchet MS, Nunito, Segoe UI, sans-serif',
  },
  {
    name: 'Roboto',
    value: 'Roboto, -apple-system, BlinkMacSystemFont, Tahoma, sans-serif',
  },
  {
    name: 'Ubuntu',
    value: 'Ubuntu, Verdana, Arial, sans-serif',
  },
]

export const getFontFamily = (font: string): FontFamily => {
  return FONT_FAMILIES.find((x: FontFamily) => x.name === font || x.value === font) ||
         { name: font, value: font }

}
export const allFonts = (): FontFamily[] => {

  const headers: FontFamily[] = [
    {
      category: 'divider',
      name: '',
      value: '',
    },
    {
      category: 'header',
      name: 'All Individual Fonts',
      value: '',
    },
  ]
  const fontList: string[][] = FONT_FAMILIES.filter((f: FontFamily) => isNil(f.category))
                                            .map((f: FontFamily) => f.value.split(','))
  const fontListEntries: FontFamily[] = uniq(flatMap(fontList).sort())
                                            .map((f: string) => ({name: f, value: f}))
  return headers.concat(fontListEntries)
}

export const limitDropdownOptions: DropdownOption[] = [
  {
    description: 'Use AI Sentence Logic',
    key: CapTextStrategy.SENTENCE_CAP,
    value: CapTextStrategy.SENTENCE_CAP,
  },
  {
    description: 'Fixed Character Limit',
    key: CapTextStrategy.EXACT_CAP,
    value: CapTextStrategy.EXACT_CAP,
  },
  {
    description: 'Do Not Trim Text',
    key: CapTextStrategy.NO_CAP,
    value: CapTextStrategy.NO_CAP,
  },
]

export const FONT_STYLES = [
  'italic',
  'normal',
]

export interface StaticImageType {
  id: number,
  name: string,
  height: number,
  width: number,
}

export interface ContextType extends StaticImageType, TemplateModule {}

export const COMMUNITY_NAME = '__COMMUNITY__'
export const DATE = '__DATE__'
export const FEATURED_TITLE = '__FEATURED_TITLE__'
export const RECOMMENDED_TITLE = '__RECOMMENDED_TITLE__'
export const TITLE = '__TITLE__'
export const DESCRIPTION = '__DESCRIPTION__'
export const USER_FIRST_NAME = '__FIRST_NAME__'

export const AI_RECOMMENDED_SUBJECT = RECOMMENDED_TITLE + ' and more'
export const BOOSTED_SUBJECT = FEATURED_TITLE + ' and more'
export const WITH_DATE = ' | ' + DATE

export const AI_RECOMMENDED_PREVIEW = DESCRIPTION

export const imagePropertyKey = (st: StaticImageType, property: string): string => {
  return `static_image_${st.id}_${property}`
}

export const EMAIL_SENT_SUCCESSFULLY = 'Your email is on its way! If you can\'t find your test email, check your spam or add @sentwithrasa.io as a trusted contact.'
export const EMAIL_SENT_FAILURE = 'Oh no, something went wrong with that attempt to send your newsletter!'

export const IMAGE_REMOVED = 'NO_SHOW'

export const HEADER_LOGO_MAX_WIDTH = 600

interface ArticleMap {
  [section: string]: Article[]
}

export interface PreviewContent {
  articles: Article[]
  sections?: ArticleMap
}

export const isValidFontFamily = (font: string): boolean => {
  return !!FONT_FAMILIES.filter((f) => f.value.includes(font)).length
}

export const isValidTab = (tab: string): boolean => {
  const validTabs = Object.keys(MenuSections)
    .filter((section) => (section !== 'uploadImage' && !section.includes('lead') &&
    !section.includes('sectionEditor')))
  return !!validTabs.filter((validTab) => validTab.toLowerCase() === tab.toLowerCase()).length
}

export const getMenuSection = (hash: string): MenuSections => {
  const match = Object.keys(MenuSections).filter((section) => section.toLowerCase() === hash.toLowerCase())[0]
  return match ? MenuSections[match] : ''
}

export const getHash = (menuSection: string): string => {
  const match = Object.keys(MenuSections).filter((section) => MenuSections[section] === menuSection)[0]
  return match ? match.toLowerCase() : ''
}

export const MAX_COUNT_BELOW_ARTICLE_MODULE_COUNT_MESSAGE = 'Your layout contains more articles than this'
export const MODULE_COUNT_ABOVE_MAX_ARTICLES_MESSAGE = 'Adjust Max Article Count'
export const NO_DATE = 'No Date'
export const DATE_FORMATS = [
  '%A, %B %d %Y',
  '%B %d %Y',
  '%d %B %Y',
  '%d/%m/%Y',
  '%m/%d/%Y',
  '%Y-%m-%d',
  '%m-%d-%Y',
  NO_DATE
]

export const getDateFormatOptions = (formats: string[]): DropdownOption[] => {
  return formats.map((format) => {
    return {
      description: format === NO_DATE ? format : strfFormatDate(new Date(), format),
      key: format,
      value: format,
    }
  })
}

export const DATE_FORMAT_OPTIONS: DropdownOption[] = getDateFormatOptions(DATE_FORMATS)

export enum AddModuleOptions {
  BannerImage = 'Banner Image',
  SquareImage = 'Square Image',
  TextHTML = 'Text/HTML',
  Article = 'Article',
  Sponsored = 'Sponsored',
}

export const AllowedDeleteModules = [
  TemplateModuleType.article,
  TemplateModuleType.image,
  TemplateModuleType.twoColumnImage,
  TemplateModuleType.leadText,
  TemplateModuleType.sponsored,
]

export enum ViewInBrowserFilter {
  NONE = 'None',
  TOP = 'Top',
  BOTTOM = 'Bottom',
}

export const VIEW_IN_BROWSER_OPTIONS: DropdownOption[] = [
  { key: ViewInBrowserFilter.NONE, value: ViewInBrowserFilter.NONE,
    description: ViewInBrowserFilter.NONE },
  { key: ViewInBrowserFilter.TOP, value: ViewInBrowserFilter.TOP,
    description: ViewInBrowserFilter.TOP },
  { key: ViewInBrowserFilter.BOTTOM, value: ViewInBrowserFilter.BOTTOM,
    description: ViewInBrowserFilter.BOTTOM },
]


export const modulesChanged = (newModules: TemplateModule[], oldModules: TemplateModule[]): boolean => {
  if ( newModules.length !== oldModules.length ) {
    return true
  }

  return newModules.reduce((numDiff, newModule, idx) => {
    const oldModule = oldModules[idx]
    if ( modulesDifferent(newModule, oldModule) ) {
      return numDiff + 1
    } else {
      return numDiff
    }
  }, 0) > 0
}

const modulesDifferent = (m1: TemplateModule, m2: TemplateModule): boolean => {
  return ( m1.sequence !== m2.sequence ||
           m1.type !== m2.type ||
           m1.hidden !== m2.hidden ||
           m1.isFirstArticleModule !== m2.isFirstArticleModule ||
           m1.moduleConfig !== m2.moduleConfig)

}

