import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as React from 'react'
import * as ReactMovable from 'react-movable'
import { Roles } from 'shared/constants'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import {
  BillingPlanDetailCode,
  canAccessPlanFeature,
  getCurrentPlanMaxLayoutModules
} from 'shared_server_client/types/billing_plan'
import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import {
  DEFAULT_MAX_LAYOUT_MODULES,
  DEFAULT_MAX_SPONSORED_CONTENT,
  TEMPLATE_MODULES
} from 'shared_server_client/constants'
import * as Utils from '../../../generic/utility'
import { noMoreUpgradeAvailable } from '../../../generic/utility'
import { EditSectionProps } from '../components'
import {
  AddModuleOptions,
  getAttribute,
  imagePropertyKey,
  MenuSections,
  modulesChanged,
  StaticImageType,
  UpgradeTemplateField,
  UpgradeTemplateFieldVal,
} from '../constants'
import { getMatchingModules, getModuleIndex } from '../utils'
import { ModuleAction, ModulePropsChangeArgs, RenderDesignModules } from './templateModule'
import { Upgrade } from 'components/icons/upgrade'

const unDraggableModules: TemplateModuleType[] = [
  TemplateModuleType.footer,
]

const footerModule: TemplateModule = {
  hidden: false,
  moduleConfig: {},
  sequence: 1,
  type: TemplateModuleType.footer,
}

interface StaticContentState {
  canAddModule: boolean,
  hasFeatureAccess: boolean,
  hasSponsoredAccess: boolean,
  hasScheduledContentAccess: boolean,
  isLoading: boolean,
  isNoMoreUpgradeAvailable: boolean,
  isSuperUser: boolean,
  showAddModule: boolean,
  types: StaticImageType[],
  maxLayoutModulesLimitReached: boolean,
  maxSponsoredPosts: number,
  maxLayoutModules: number,
}
const AllowedFirstArticleModules = [
  TemplateModuleType.article,
  TemplateModuleType.section,
  TemplateModuleType.sponsored,
]

export class TemplateLayout extends React.Component<EditSectionProps, StaticContentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: EditSectionProps) {
    super(props)
    this.state = {
      canAddModule: false,
      hasFeatureAccess: false,
      hasSponsoredAccess: false,
      hasScheduledContentAccess: false,
      maxSponsoredPosts: DEFAULT_MAX_SPONSORED_CONTENT,
      maxLayoutModules: DEFAULT_MAX_LAYOUT_MODULES,
      maxLayoutModulesLimitReached: false,
      isLoading: true,
      isNoMoreUpgradeAvailable: false,
      isSuperUser: false,
      showAddModule: false,
      types: [],
    }
    this.switch = this.switch.bind(this)
  }

  public componentDidUpdate(prevProps: Readonly<EditSectionProps>): void {
    const maxLimitReached = this.maxLayoutModulesLimitReached(this.state.maxLayoutModules)
    if(this.state.maxLayoutModulesLimitReached !== maxLimitReached) {
      this.setState({
        maxLayoutModulesLimitReached: maxLimitReached,
      })
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.role),
      new Dataset().loadGlobalDataset('staticImageTypes'),
    ]).then(([activeCommunity, role, staticImageTypesResponse]) => {
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      const sponsoredFeature: any = activeCommunity.billingInfo.currentPlan.details.find((x) =>
        x.billing_plan_code === BillingPlanDetailCode.MAX_SPONSORED_POSTS)
      const maxLayoutModules = getCurrentPlanMaxLayoutModules(activeCommunity.billingInfo.currentPlan)
      this.setState({
        canAddModule: role === Roles.super_admin || (maxLayoutModules !== 0),
        hasFeatureAccess: avlFeatures.indexOf(BillingPlanDetailCode.SCHEDULED_CONTENT) > -1,
        hasSponsoredAccess: sponsoredFeature && sponsoredFeature.limit_val !== 0,
        hasScheduledContentAccess: canAccessPlanFeature(
          BillingPlanDetailCode.SCHEDULED_CONTENT, activeCommunity.billingInfo.currentPlan),
        maxSponsoredPosts: sponsoredFeature ? sponsoredFeature.limit_val : DEFAULT_MAX_SPONSORED_CONTENT,
        maxLayoutModules,
        maxLayoutModulesLimitReached: this.maxLayoutModulesLimitReached(maxLayoutModules),
        isLoading: false,
        isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
        isSuperUser: role === Roles.super_admin,
        types: staticImageTypesResponse[0],
      })
      this.updateFirstArticleIndex(this.props.data.template_modules)
    })
  }

  public render() {
    return <div className="static-content-wrapper">
      <div className="title">
        {MenuSections.layout}
        {
          this.renderAddModuleButton()
        }
      </div>
      <div className="section">
        {this.state.isLoading ? <Loading size="64" /> :
        <div className="drag-drop-container">
          <ReactMovable.List
            lockVertically={true}
            values={this.props.data.template_modules
              .filter((x: TemplateModule) =>  unDraggableModules.indexOf(x.type) === -1)
              .map((templateModule: TemplateModule) =>
              <RenderDesignModules {...this.props.data} {...templateModule}
                hasFeatureAccess={this.state.hasFeatureAccess}
                onChange={this.props.onChange}
                onModulePropsChange={this.modulePropsChange}
                showDelete={this.state.isSuperUser}
                select={this.props.select}
                choose={this.props.choose}
                staticImageTypes={this.state.types}
                templateModules={this.props.data.template_modules}
                sectionSelect={this.props.sectionSelect}
              />,
            )}
            onChange={this.listOnChange}
            renderItem={({ value, props }) => <div className="render-item" {...props}>{value}</div>}
            renderList={({ children, props }) => <div className="render-list" {...props}>{children}</div>}
          />
          <RenderDesignModules {...this.props.data} {...footerModule}
            hasFeatureAccess={this.state.hasFeatureAccess}
            onChange={this.props.onChange}
            onModulePropsChange={this.modulePropsChange}
            select={this.props.select}
            showDelete={this.state.isSuperUser}
            choose={this.props.choose}
            staticImageTypes={this.state.types}
            templateModules={this.props.data.template_modules}
            sectionSelect={this.props.sectionSelect}
          />
        </div>
        }
      </div>
    </div>
  }

  private renderAddModuleButton = () => {
    const hasFeatureAccess = this.state.isSuperUser || !this.state.maxLayoutModulesLimitReached

    return this.state.canAddModule ?
      <div className="title-action">
        <div className='add-module-action needMoreCta'>
          {
            !hasFeatureAccess &&
            <h1 className="integration-category-title line-0"  onClick={this.onClickFeatureNotAvailable}>
              <span className="premium-badge mr-2">
                <Upgrade svgwidth="20" svgheight="20"/> Need more modules?
              </span>
            </h1>
          }
          <i className={`action-icon ${this.state.showAddModule ? 'fa fa-window-close' : 'fas fa-plus'}`}
             onClick={this.toggleShowAddModule}></i>
          {
            this.state.showAddModule && <div className="actions">
              {
                this.getModuleOptions()
                  .filter((x) => x !== AddModuleOptions.Sponsored || this.state.isSuperUser)
                  .map((item, i) =>
                    <div key={i} className={`action ${!hasFeatureAccess ? 'upgrade' : ''}`}
                         onClick={() => !hasFeatureAccess ?
                           this.onClickFeatureNotAvailable() :
                           this.addModule(AddModuleOptions[item])}>
                      <div className="action-title">
                        {AddModuleOptions[item]}
                      </div>
                      {hasFeatureAccess !== true && <Upgrade svgwidth="20" svgheight="20"/>}
                    </div>
                  )}
            </div>
          }
        </div>
      </div> : null
  }

  private onClickFeatureNotAvailable = () => {
    const isNoMoreUpgradeAvailable = this.state.isNoMoreUpgradeAvailable
    const val = isNoMoreUpgradeAvailable ?
      UpgradeTemplateFieldVal.contactToRasa : UpgradeTemplateFieldVal.layout

    this.props.onChange(UpgradeTemplateField, val)
  }

  private toggleShowAddModule = () => {
    this.setState({
      showAddModule: !this.state.showAddModule,
    })
  }
  private maxLayoutModulesLimitReached = (maxLayoutModules) => {
    const layoutModuleCount = this.props.data.template_modules.filter((x) =>
      x.isManuallyAdded).length
    return layoutModuleCount >= maxLayoutModules
  }
  private getModuleOptions = () => {
    const sponsoredModuleCount = this.props.data.template_modules.filter((x) =>
      x.type === TemplateModuleType.sponsored).length
    return Object.keys(AddModuleOptions).filter((item) =>
      item !== AddModuleOptions.Sponsored || (this.state.hasSponsoredAccess &&
      sponsoredModuleCount < this.state.maxSponsoredPosts))
  }
  private addModule = (moduleType) => {
    let newTemplateModules: TemplateModule[] = []
    newTemplateModules = this.props.data.template_modules
    const templateType = this.getModuleType(moduleType)
    // eslint-disable-next-line prefer-spread
    const existingSequence = Math.max.apply(Math, newTemplateModules
      .filter((x) => x.type === templateType).map((x) => x.sequence))
    let newTemplateModule: TemplateModule = null
    if (templateType === TemplateModuleType.article) {
      newTemplateModule = this.addArticleModule()
      const restOfArticlesModuleIndex = getModuleIndex(this.props.data.template_modules,
        TemplateModuleType.restOfArticles, 1)
      const restOfArticleModule = this.props.data.template_modules[restOfArticlesModuleIndex]
      restOfArticleModule.moduleConfig.start = newTemplateModule.moduleConfig.end
      newTemplateModules.splice(restOfArticlesModuleIndex, 1)
      newTemplateModules.splice(restOfArticlesModuleIndex, 0, newTemplateModule)
      newTemplateModules.splice(restOfArticlesModuleIndex + 1, 0, restOfArticleModule)
    } else {
      newTemplateModule = {
        hidden: false,
        sequence: existingSequence && existingSequence !== -Infinity  ? existingSequence + 1 : 1,
        moduleConfig: this.getModuleConfig(templateType),
        type: templateType,
        isManuallyAdded: templateType !== TemplateModuleType.sponsored ? true : false,
    }
      newTemplateModules.splice(-1, 0, newTemplateModule)
    }
    this.props.onChange(TEMPLATE_MODULES, newTemplateModules)
    this.toggleShowAddModule()
  }

  private getModuleConfig = (moduleType: TemplateModuleType) => {
    let config = {}

    if (this.state.hasScheduledContentAccess) {
      switch (moduleType) {
        case TemplateModuleType.image:
        case TemplateModuleType.twoColumnImage:
        case TemplateModuleType.leadText:
          config = {
            isScheduled: true
          }
          break
      }
    }

    return config
  }

  private addArticleModule = (): TemplateModule => {
    const lastArticleModule = this.props.data.template_modules
      .filter((x) => x.type === TemplateModuleType.article).slice(-1)[0]
    return {
      hidden: false,
      sequence: lastArticleModule ? lastArticleModule.sequence + 1 : 1,
      isManuallyAdded: true,
      moduleConfig: {
        count: 1,
        start: lastArticleModule ? lastArticleModule.moduleConfig.end : 0,
        end: lastArticleModule ? lastArticleModule.moduleConfig.end + 1 : 1,
      },
      type: TemplateModuleType.article,
    }
  }
  private getModuleType = (moduleType) => {
    switch (moduleType) {
      case AddModuleOptions.BannerImage:
        return TemplateModuleType.image
      case AddModuleOptions.SquareImage:
        return TemplateModuleType.twoColumnImage
      case AddModuleOptions.TextHTML:
        return TemplateModuleType.leadText
      case AddModuleOptions.Sponsored:
        return TemplateModuleType.sponsored
      default:
        return TemplateModuleType.article
    }
  }
  private modulePropsChange = (change: ModulePropsChangeArgs) => {
    if (change.action === ModuleAction.hide) {
      this.handleHidePropsChange(change)
    } else {
      // clone/edit/delete
      if ([TemplateModuleType.article, TemplateModuleType.section].includes(change.module.type)) {
        this.handleArticlePropsChange(change)
      } else {
        this.handleNonArticlePropsChange(change)
      }
    }
  }

  private handleNonArticlePropsChange = (change: ModulePropsChangeArgs) => {
    const newTemplateModules: TemplateModule[] = this.props.data.template_modules
    if (change.action === ModuleAction.delete) {
      const deleteModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
      newTemplateModules.splice(deleteModuleIndex, 1)
    }
    this.props.onChange('template_modules', newTemplateModules)
  }

  private updateFirstArticleIndex = (inputModules: TemplateModule[]): void => {
    let firstArticleSet = false
    inputModules.forEach((item) => {
      if (AllowedFirstArticleModules.includes(item.type)) {
        item.isFirstArticleModule = firstArticleSet ? false : true
        firstArticleSet = true
      }
    })
  }
  private handleHidePropsChange = (change: ModulePropsChangeArgs) => {
    const newTemplateModules: TemplateModule[] = structuredClone(this.props.data.template_modules)
    const currentModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
    newTemplateModules[currentModuleIndex] = change.module
    if ( modulesChanged(newTemplateModules, this.props.data.template_modules) ) {
      this.props.onChange('template_modules', newTemplateModules)
    }
  }

  private handleArticlePropsChange = (change: ModulePropsChangeArgs) => {
    const newTemplateModules: TemplateModule[] = structuredClone(this.props.data.template_modules)
    if (change.action === ModuleAction.clone) {
      const oldModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.oldSequence)
      newTemplateModules.splice(oldModuleIndex + 1, 0, change.module)
    } else if (change.action === ModuleAction.delete) {
      const deleteModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
      newTemplateModules.splice(deleteModuleIndex, 1)
    }

    const currentModuleIndex = getModuleIndex(newTemplateModules, change.module.type, change.module.sequence)
    const restOfArticlesModuleIndex = getModuleIndex(newTemplateModules, TemplateModuleType.restOfArticles, 1)

    const foundModules = getMatchingModules(newTemplateModules, change.module)
    let consumedCount: number = 0
    foundModules.forEach((foundModule) => {
      if (foundModule.index < currentModuleIndex) {
        consumedCount += foundModule.module.moduleConfig.count
      } else if (foundModule.index === currentModuleIndex) {
        // same module.
        change.module.moduleConfig.start = consumedCount
        change.module.moduleConfig.end = consumedCount + change.module.moduleConfig.count
        newTemplateModules[foundModule.index] = change.module
        consumedCount += change.module.moduleConfig.count
      } else {
        foundModule.module.moduleConfig.start = consumedCount
        foundModule.module.moduleConfig.end = consumedCount + foundModule.module.moduleConfig.count
        newTemplateModules[foundModule.index] = foundModule.module
        consumedCount += foundModule.module.moduleConfig.count
      }
    })

    // now reset the index for rest of articles
    const restOfArticlesModule = newTemplateModules[restOfArticlesModuleIndex]
    restOfArticlesModule.moduleConfig.start = consumedCount
    newTemplateModules[restOfArticlesModuleIndex] = restOfArticlesModule
    this.updateFirstArticleIndex(newTemplateModules)
    if ( modulesChanged(newTemplateModules, this.props.data.template_modules) ) {
      this.props.onChange('template_modules', newTemplateModules)
    }
  }

  private listOnChange = (move: ReactMovable.OnChangeMeta) => {
    const newTemplateModules: TemplateModule[] = ReactMovable.arrayMove(
      this.props.data.template_modules, move.oldIndex, move.newIndex)
    this.updateFirstArticleIndex(newTemplateModules)
    this.props.onChange('template_modules', newTemplateModules)
  }

  private switch(st: StaticImageType) {
    if (st && st.id) {
      const currentStatus = Utils.isTruthy(getAttribute(this.props.data, imagePropertyKey(st, 'is_active')))
      this.props.onChange(imagePropertyKey(st, 'is_active'), !currentStatus)
    }
  }
}
