import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as GA from 'google-analytics'
import {pipe, tap} from 'lodash/fp'
import * as React from 'react'
import {connect} from 'react-redux'
import * as ReactStrap from 'reactstrap'
import * as Router from '../../router'

export const preventDefaultThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.preventDefault()),
  fn,
)

export const stopPropagationThen = (fn: any) => pipe(
  tap((e: React.SyntheticEvent) => e.stopPropagation()),
  fn,
)

export const preventDefault = () => (e: React.SyntheticEvent) => e.preventDefault()

interface ComponentProps {
  children: any,
  href: string,
}

interface DispatchProps {
  push: (href: string) => Router.PushAction,
}

type Props = ComponentProps & DispatchProps

export const Link = connect(
  null,
  {push: Router.push},
)(({href, push, children, ...props}: Props) =>
<ReactStrap.Button {...props} onClick={preventDefaultThen(() => push(href))}>
  {children}
</ReactStrap.Button>)

interface AnchorLinkOwnProps {
  ga4Event?: GA.Event4,
  dispatch?: any,
  callback?: any,
  style?: any,
}

type AnchorLinkProps = ComponentProps & AnchorLinkOwnProps
//& React.HTMLProps<HTMLAnchorElement>

export const AnchorLink = connect(
  (state: any, ownProps: AnchorLinkOwnProps) => (ownProps),
  (dispatch) => ({dispatch}),
)(({href, children, ga4Event, ...props}: AnchorLinkProps) =>
  <a href={href}
    className="anchor-link"
    onClick={preventDefaultThen(() => {
      if ( ga4Event ) {
        props.dispatch(GA.sendGa4Event(ga4Event))
      }
      if ( href ) {
        props.dispatch(Router.push(href))
      }
      if (props.callback) {
        props.callback()
      }},
    )}
    style={props.style}>
    {children}
  </a>)

interface UpgradeLinkProps {
  callback?: any,
  children: any,
  className?: string,
  source: string,
  style?: any,
}

export const UpgradeAnchorLink = connect(
  (state, props: UpgradeLinkProps) => (props),
)((props: UpgradeLinkProps) => {
  return <AnchorLink ga4Event={GA.createUpgradeEvent(props.source)}
                     style={props.style}
                     href={DashboardMenuOption.billing + '?upgrade_from=' + props.source}
                     callback={() => {
                       sessionStorage.setItem(GA.UPGRADE_FROM, props.source)
                       if (props.callback) {
                         props.callback()
                       }
                     }}>
    {props.children}
  </AnchorLink>
})
