import { Post } from 'components/content-pool/edit-article/types'
import { getModuleConfigValue, getModuleIndex, getStaticTextStylesAttribute, isScheduledModule} from 'components/email-layout/utils'
import { FrameLandscapeBigIcon } from 'components/icons/frame-landscape-big'
import { TextIcon } from 'components/icons/text-icon'
import { Loading } from 'components/loading'
import { isEmpty, shuffle } from 'lodash'
import * as React from 'react'
import * as Constants from 'shared/templates/constants'
import {
  BANNER_IMAGE_MODULE,
  leadTextAttributes,
  LEFT_SQUARE,
  RIGHT_SQUARE,
  SQUARE_IMAGE_MODULE,
} from 'shared_server_client/constants'
import { SectionConfig, TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import * as CommonUtils from 'shared_server_client/utils'
import * as Utils from '../../../generic/utility'
import { loadSampleArticles } from '../../../shared/utils';
import { CompanyAddress } from '../address'
import {
  Article,
  EmailAttributes,
  getAttribute,
  Image,
  IMAGE_REMOVED,
  leadTextDefaults,
  PreviewContent,
  Text,
  ViewInBrowserFilter,
} from '../constants'
import '../styles.scss'

export interface EmailLayoutProps {
  content: PreviewContent,
  images?: Image[],
  isLoading: boolean,
  session: any,
  previewDate?: Date,
  hideModeBanner?: boolean,
  hidePlaceHolders?: boolean,
  showRasaLogo?: boolean,
  template?: string,
  communityId?: string,
  texts?: Text[]
}

interface BaseLayoutState {
  articles?: Article[],
  sample_articles?: Article [],
  [key: string]: any
}

export class BaseLayoutComponent<S = any> extends React.Component<EmailLayoutProps, BaseLayoutState> {
  protected templateClassName: string = ''
  constructor(props: EmailLayoutProps) {
    super(props)
    this.state = {
      articles: [],
      sample_articles: [],
    }
  }

  get visibleModules(): TemplateModule[] {
    return (this.getAttribute('template_modules') || []).filter((m) => m.hidden === false)
  }

  public componentDidMount() {
    loadSampleArticles().then((contentList) => {
      this.setState({
        articles: contentList.slice(0, 3),
        sample_articles: contentList,
      })
    })
  }

  public render() {
    if (this.props.isLoading || isEmpty(this.visibleModules)) {
      return <Loading size="64" />
    }
    return <div className={this.templateClassName}>
      {!this.props.hideModeBanner && this.modeBanner()}
      {this.props.isLoading && <div className="article-loader"><Loading size="64" /></div>}
      <div className="template-body">
        {this.visibleModules.map((module: any, index: number) => {
          return this.renderModule(module, index)
        })}
      </div>
    </div>
  }

  protected renderModule = (module: TemplateModule, key: number) => {
    if (module.hidden) {
      return null
    }

    if (module.type === TemplateModuleType.header) {
      return <div key={key} className="module-container">{this.headerModule()}</div>
    } else if (module.type === TemplateModuleType.leadText && !this.props.hidePlaceHolders) {
      return <div key={key} className="module-container">{this.leadTextComponent(module)}</div>
    } else if (module.type === TemplateModuleType.image && !this.props.hidePlaceHolders) {
      const staticImageName = 'Image'
      return <div key={key} className="module-container">
        {this.bannerImageComponent(module, staticImageName)}
      </div>
    } else if (module.type === TemplateModuleType.article) {
      return <div key={key} className="module-container">{this.articleModule(module)}</div>
    } else if (module.type === TemplateModuleType.sponsored) {
      return <div key={key} className="module-container">{this.sponsoredComponent(module)}</div>
    } else if (module.type === TemplateModuleType.twoColumnImage && !this.props.hidePlaceHolders) {
      return <div key={key} className="module-container">{this.twoColumnImageComponent(module)}</div>
    } else if (module.type === TemplateModuleType.restOfArticles) {
      return <div key={key} className="module-container">{this.restOfArticlesModule(module)}</div>
    } else if (module.type === TemplateModuleType.section) {
      return <div key={key} className="module-container">{this.sectionModule(module)}</div>
    } else if (module.type === TemplateModuleType.footer) {
      return <div key={key} className="module-container">{this.footerModule()}</div>
    }

    return null
  }

  // renderring methods
  protected headerModule = () => null
  protected articleModule = (module: TemplateModule) => null
  protected restOfArticlesModule = (module: TemplateModule) => null
  protected sectionModule = (module: TemplateModule) => null
  protected sponsoredModule = (module: TemplateModule) => null
  protected footerModule = () => {
    return <div className="template-footer" style={this.footerStyle()}>
      <div className="footer-links">
        {this.socialMediaLink(EmailAttributes.twitterName, 'x')}
        {this.socialMediaLink(EmailAttributes.instagramName, 'instagram')}
        {this.socialMediaLink(EmailAttributes.linkedInName, 'linkedin')}
        {this.socialMediaLink(EmailAttributes.facebookName, 'facebook')}
        {this.socialMediaLink(EmailAttributes.youtubeName, 'youtube')}
        {this.socialMediaLink(EmailAttributes.threadsName, 'threads')}
      </div>
      {this.getAttribute(EmailAttributes.footerTextFieldOne)
      || this.getAttribute(EmailAttributes.footerTextFieldTwo) ?
      <div className="footer-text">
        <p className="footer-1">
          {this.getAttribute(EmailAttributes.footerTextFieldOne) || ''}
        </p>
        <p className="footer-2">
          {this.getAttribute(EmailAttributes.footerTextFieldTwo) || ''}
        </p>
      </div> : null}
      {this.getAttribute(EmailAttributes.brandFooterImageUrl) !== IMAGE_REMOVED &&
      <div className="template-footer-logo-wrapper">
        <a href={this.getAttribute(EmailAttributes.brandFooterUrl) ||
                this.getAttribute(EmailAttributes.brandHeaderUrl)}
          target="_blank" rel="noopener">
          <img className="template-footer-image"
          src={`${this.getAttribute(EmailAttributes.brandFooterImageUrl) || this.getAttribute(EmailAttributes.brandHeaderImageUrl) }`}
          alt={this.getAttribute(EmailAttributes.brandFooterAltText)}
          style={{height: `${this.getAttribute(EmailAttributes.footerLogoSize)}`, width: 'auto'}} />
        </a>
      </div>}
      <div className="footer-text">
        <p className="footer-company">
          {this.getAttribute(EmailAttributes.companyName) || ''}
        </p>
        <p className="footer-address">
          <CompanyAddress data={this.props.session}/>
        </p>
      </div>
      <div className="reserved-rights">
        <p>
        {this.getAttribute(EmailAttributes.footerCopyRightText)} &copy; {new Date().getFullYear()}
        </p>
      </div>
      <div className={`bottom-links ${this.props.hidePlaceHolders ? 'rasa-noclick' : ''}`}>
        {this.getAttribute(EmailAttributes.subscribeUrl) &&
        <span>
          <a className="footer-text-color" href="#" rel="noopener">
            {this.getAttribute(EmailAttributes.subscribeUrlText)}
          </a>&nbsp;|&nbsp;
        </span>}
        <span>
          <a className="footer-text-color" href="#" rel="noopener">
            {this.getAttribute(EmailAttributes.feedbackUrlText)}
          </a>
        </span>
        {!!this.getAttribute(EmailAttributes.learnMoreUrl) &&
        <span>
          &nbsp;|&nbsp;
          <a className="footer-text-style footer-text-color"
            href={this.getAttribute(EmailAttributes.learnMoreUrl)} target="_blank" rel="noopener">
              {this.getAttribute(EmailAttributes.learnMoreUrlText)}
          </a>
        </span>}
        <span>
          &nbsp;|&nbsp;
          <a className="footer-text-style footer-text-color" href="#" rel="noopener">
            {this.getAttribute(EmailAttributes.unsubscribeUrlText)}
          </a>
        </span>
        {!!this.getAttribute(EmailAttributes.contactUsUrl) &&
        <span>
          &nbsp;|&nbsp;
          <a className="footer-text-style footer-text-color"
            href={this.getAttribute(EmailAttributes.contactUsUrl)} target="_blank" rel="noopener">
              {this.getAttribute(EmailAttributes.contactUsUrlText)}
          </a>
        </span>}
      </div>
      {this.getAttribute(EmailAttributes.viewInBrowser) &&
       this.getAttribute(EmailAttributes.viewInBrowser) === ViewInBrowserFilter.BOTTOM &&
        <div style={{float: 'right'}} className="bottom-links">
          <a href="#">
            <p className="footer-text-style footer-text-color">
                View in Browser
            </p>
          </a>
        </div>
      }
      {Utils.isTruthy(this.getAttribute(EmailAttributes.showRasaLogo)) && !this.props.hidePlaceHolders ?
      <div className="rasa-footer">
        <img className="fake-link" alt="rasa.io" src={CommonUtils.footerLogo(this.props.session)}></img>
      </div> : null}
    </div>
  }
  protected footerStyle = () => null
  protected isWhiteFooter = (): boolean => false

  protected getSectionArticles = (module: TemplateModule) => {
    let sectionedArticles = []
    if (this.props.content && this.props.content.sections) {
      const section = this.props.session.sections.find((x) => x.id === module.moduleConfig.sectionId)
      sectionedArticles = section && this.props.content.sections[section.name]
        ? this.props.content.sections[section.name] : []
    }
    if (sectionedArticles.length === 0) {
      sectionedArticles = shuffle(this.state.sample_articles).slice(0, module.moduleConfig.count)
    }
    return sectionedArticles
  }

  protected getSectionDetails = (module: TemplateModule) => {
    if (this.props.content && this.props.content.sections) {
      const section = this.props.session.sections.find((x) => x.id === module.moduleConfig.sectionId)
      return section
    } else {
      return null
    }
  }

  protected leadTextComponent(module: TemplateModule) {
    const {texts} = this.props
    const {static_texts} = this.props.session
    const staticTextType = `Text${module.sequence}`
    const staticText = static_texts ? static_texts[staticTextType] : null
    const scheduledText = texts ? texts.filter((t) => t.lead_text_type === staticTextType)[0] : null
    if (Utils.isTruthy(module.hidden) || module.moduleConfig.isScheduled && !scheduledText) {
      return null
    } else if (module.moduleConfig.isScheduled) {
        return <div className="static-lead-text-wrapper">
                {this.placeholderText(module.sequence, true)}
              </div>
    } else {
      return <div className="static-lead-text-wrapper">
        {staticText && staticText[leadTextAttributes.text]
        ? <div className="static-lead-text-content"
              style={{
                textAlign: this.hasInnerAlignment(staticText) ? null : 'center',
                fontFamily: this.getAttribute(EmailAttributes.newsletterFontFamily),
                fontSize: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.fontSize, leadTextDefaults.fontSize),
                fontStyle: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.fontStyle, leadTextDefaults.fontStyle),
                fontWeight: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.fontWeight, leadTextDefaults.fontWeight),
                color: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.color, leadTextDefaults.color),
                borderWidth: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.borderWidth, leadTextDefaults.borderWidth),
                borderColor: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.borderColor, leadTextDefaults.borderColor),
                borderStyle: 'solid',
                padding: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.borderWidth, leadTextDefaults.borderWidth) !== '0px'
                ? '10px' : null,
              }}
              dangerouslySetInnerHTML={{__html: staticText[leadTextAttributes.text]}}>
            </div>
        : <div className="static-lead-text-content" style={{
              fontFamily: this.getAttribute(EmailAttributes.newsletterFontFamily),
              fontSize: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.fontSize, leadTextDefaults.fontSize),
              fontWeight: getStaticTextStylesAttribute
                (this.props.session, staticTextType, leadTextAttributes.fontWeight, leadTextDefaults.fontWeight)}}>
            <div className="static-lead-text-content-title">Text / HTML</div>
            <div className="static-lead-text-content-description">
              Use this block to display text for every subscriber.
              Great for promotions, announcements and more!
            </div>
          </div>}
      </div>
    }
  }

  protected sectionTitleStyles = (module: TemplateModule) => {
    return {
      border: `${getModuleConfigValue(module, SectionConfig.SECTION_BORDER_SIZE) || '1px'} solid ${getModuleConfigValue(module, SectionConfig.SECTION_BORDER_COLOR) || this.getAttribute(EmailAttributes.primaryColor)}`,
      padding: '5px',
      fontFamily: this.getAttribute(`${EmailAttributes.newsletterFontFamily}`) || 'Arial',
      fontSize: getModuleConfigValue(module, SectionConfig.SECTION_FONT_SIZE) || '12px',
      fontWeight: getModuleConfigValue(module, SectionConfig.SECTION_FONT_WEIGHT) || 'normal',
      color:  getModuleConfigValue(module, SectionConfig.SECTION_FONT_COLOR) || '#000000',
    }
  }

  protected nextModule = (module: TemplateModule): TemplateModule => {
    const currentIndex = getModuleIndex(this.visibleModules, module.type, module.sequence)
    if (this.visibleModules.length > currentIndex) {
      return this.visibleModules[currentIndex + 1]
    }
    return null
  }

  protected previousModule = (module: TemplateModule): TemplateModule => {
    const currentIndex = getModuleIndex(this.visibleModules, module.type, module.sequence)
    if (currentIndex > 0) {
      return this.visibleModules[currentIndex - 1]
    }
    return null
  }

  protected cappedDescription = (description: string): string => {
    const textLength: number = Number(this.getAttribute(EmailAttributes.descriptionLength) || 200)
    const strategy: CommonUtils.CapTextStrategy = this.getAttribute(EmailAttributes.descriptionCharacterLimit)
    const result = CommonUtils.capText(description, textLength, strategy)
    return result.isCapped ? `${result.text}...` : result.text
  }

  protected modeBanner = () => {
    return <p className="mode-banner">
        {Constants.DESIGN_MODE.description}
      </p>
  }

  protected getAttribute = (attribute: (EmailAttributes | string)): any => {
    return getAttribute(this.props.session, attribute)
  }

  protected getFontSize(): string {
    return !this.getAttribute(EmailAttributes.logoSize) ? '50px' :
    `${Number(this.getAttribute(EmailAttributes.logoSize).substring(0, this.getAttribute(EmailAttributes.logoSize).length - 2)) / 6}px`
  }

  protected socialMediaLink = (attr: EmailAttributes, name: string) => {
    const link = this.getAttribute(attr)
    const isWhiteFooter = this.isWhiteFooter()
    return isEmpty(link) ? null :
      <a href={link} target="_blank" className="footer-link" rel="noopener">
        <img alt={`${name}.com`}
            src={CommonUtils.socialMediaIcon(name, this.props, {requiresBlack: isWhiteFooter})}/>
      </a>
  }

  protected showImage = (post: Post, slotHeight: number, slotWidth: number): boolean => {
    if (isEmpty(post.hosted_image_url) ||
      !Utils.isTruthy(this.getAttribute(EmailAttributes.showNewsletterImages))) {
        return false
    } else {
      if (Utils.isTruthy(this.getAttribute(EmailAttributes.restrictNewsletterImages))) {
        if (!post.image_height || !post.image_width || !slotHeight || !slotWidth) {
          return true
        } else {
          const minWidth = slotWidth * 0.9
          const minHeight = slotHeight * 0.9
          return post.image_width > minWidth && post.image_height > minHeight
        }
      } else {
        return true
      }
    }
  }

  protected hasInnerAlignment(staticText: any): boolean {
    const lt: string = staticText[leadTextAttributes.text]
    const hasIt: boolean = (lt && lt.indexOf('class="ql-align') > 0)
    return hasIt
  }

  protected twoColumnImageComponent(module) {
    const isSchedModule = isScheduledModule(module)
    if (module.hidden) {
      return null
    } else if (isSchedModule) {

      return <div className="static-images-center-wrapper-container">
        <div className="static-images-center-wrapper">
          <div className="static-image-wrapper">
            {this.squarePlaceholderImage(LEFT_SQUARE, module.sequence , true)
            //TODO: use scheduled image placeholder
            }

          </div>
          <div className="static-image-wrapper">
            {this.squarePlaceholderImage(RIGHT_SQUARE, module.sequence, true)
            //TODO: use scheduled image placeholder
            }
          </div>
        </div>
      </div>
    } else {
        const staticImages = this.getAttribute('static_images')
        const leftStaticImage = CommonUtils.getStaticImage(
          staticImages, module.type, module.sequence, LEFT_SQUARE)
        const rightStaticImage = CommonUtils.getStaticImage(
          staticImages, module.type, module.sequence, RIGHT_SQUARE)
        return <div className="static-images-center-wrapper-container">
          <div className="static-images-center-wrapper">
            <div className="static-image-wrapper">
              {staticImages && leftStaticImage.static_image_type_id
                && staticImages[leftStaticImage.static_image_type_id]
              ? <a href={staticImages[leftStaticImage.static_image_type_id].url} target="_blank" rel="noopener">
                  <img className="square-image-static" src={staticImages[leftStaticImage.static_image_type_id].hosted_image_url}/>
                </a>
              : this.squarePlaceholderImage(LEFT_SQUARE, module.sequence , false)
              }
            </div>
            <div className="static-image-wrapper">
              {staticImages && rightStaticImage.static_image_type_id
                && staticImages[rightStaticImage.static_image_type_id]
              ? <a href={staticImages[rightStaticImage.static_image_type_id].url} target="_blank" rel="noopener">
                  <img className="square-image-static" src={staticImages[rightStaticImage.static_image_type_id].hosted_image_url} />
                </a>
              : this.squarePlaceholderImage(RIGHT_SQUARE, module.sequence, false)
              }
            </div>
          </div>
        </div>
    }
  }

  protected bannerPlaceholderImage = (sequence: number, scheduled: boolean) => {
    const displayName = CommonUtils.getImageDisplayName(BANNER_IMAGE_MODULE, sequence)
    return <div className="static-image-placeholder-wrapper">
      <FrameLandscapeBigIcon/>
      <div className="static-image-description">
        {`${scheduled ? 'Scheduled ' : ''}${displayName}`}<br></br>
      </div>
    </div>
  }
  protected squarePlaceholderImage = (location: string, sequence: number, scheduled: boolean) => {
    const displayName = CommonUtils.getImageDisplayName(SQUARE_IMAGE_MODULE, sequence, location)
    return <div className="static-image-placeholder-wrapper">
      <FrameLandscapeBigIcon/>
      <div className="static-image-description">
        {`${scheduled ? 'Scheduled ' : ''}${displayName}`}<br></br>
      </div>
    </div>
  }

  protected sponsoredPlaceholder = (sequence: number) => {
    const displayName = CommonUtils.getSponsoredName(sequence)
    return <div className="static-image-placeholder-wrapper">
      <FrameLandscapeBigIcon/>
      <div className="static-image-description">
        {displayName}<br></br>
      </div>
    </div>
  }

  protected placeholderText = (staticTextTypeId: number = null, scheduled: boolean) => {
    return <div className="static-text-placeholder-wrapper">
      <TextIcon/>
      <div className="static-text-description">
        {`${scheduled ? 'Scheduled ' : ''}Text ${staticTextTypeId ? '#' + staticTextTypeId : ''}`}<br></br>
      </div>
    </div>
  }

  protected bannerImageComponent(module: TemplateModule, name: string) {
    const isSchedModule = isScheduledModule(module)
    if (!isEmpty(module)) {
      if (isSchedModule) {
        return <div className="static-image-wrapper">
                  {this.bannerPlaceholderImage(module.sequence, true)}
                </div>
      } else {
          const staticImages = this.getAttribute('static_images')
          const image = CommonUtils.getStaticImage(staticImages, module.type, module.sequence)
          return <div className="static-image-wrapper">
                    {image.static_image_type_id && staticImages[image.static_image_type_id]
                      && staticImages[image.static_image_type_id].hosted_image_url
                    ? <img className="banner-image-static"
                        src={staticImages[image.static_image_type_id].hosted_image_url}/>
                    : this.bannerPlaceholderImage(module.sequence, false)
                    }
                  </div>
      }
    }
  }

  protected sponsoredComponent(module: TemplateModule) {
    return <div className="static-image-wrapper">
      {this.sponsoredPlaceholder(module.sequence)}
    </div>
  }

  protected renderViewInBrowserAtTop = (color: string) => {
    return <div className="viewinbrowser">
      <a href="#">
        <p style={{color: this.getAttribute(color)}}>
            View in Browser
        </p>
      </a>
    </div>
  }
}
