import * as Dropdown from 'components/dropdown/component'
import { isTruthy } from 'generic/utility'
import * as React from 'react'
import { Input } from 'reactstrap'
import { CapTextStrategy } from 'shared_server_client/utils'
import { RasaReactComponent } from '../../../generic/rasaReactComponent'
import { EditSectionProps } from '../components'
import { EmailAttributes, getAttribute, limitDropdownOptions, MAX_COUNT_BELOW_ARTICLE_MODULE_COUNT_MESSAGE } from '../constants'

const initialState = {
  alertMessage: {
    maxCount: '',
  },
  validate: {
    [EmailAttributes.minArticles]: 'valid',
    [EmailAttributes.maxArticles]: 'valid',
    [EmailAttributes.descriptionLength]: 'valid',
    [EmailAttributes.maxArticlesPerSource]: 'valid',
  },
}

export class NewsletterSetting extends RasaReactComponent<EditSectionProps, any> {
  constructor(props: EditSectionProps) {
    super(props, 'countsEdit', initialState)
  }
  public render() {
    return <div className="counts-editor">
      <div className="section">
        <div className="block">
          <div className="title character-count">
            Description Length
          </div>
          <div className="description">
            How do you want your article descriptions to be displayed?
          </div>
          <div className="character-limit-dropdown">
            <div className="character-editor-drop-downs-wrapper">
              <Dropdown.DropdownComponent data={limitDropdownOptions}
                                          selected={
                                            getAttribute(
                                              this.props.data,
                                              EmailAttributes.descriptionCharacterLimit)}
                                          onChange={(e) => {
                                            this.props.onChange(
                                              EmailAttributes.descriptionCharacterLimit,
                                              e.selected.key,
                                            )}}/>
            </div>
            {
              getAttribute(this.props.data, EmailAttributes.descriptionCharacterLimit) !== CapTextStrategy.NO_CAP &&
                <div>
                    <br/>
                    <div className="description-section">
                        Target Character Count
                    </div>
                    <Input value={getAttribute(this.props.data, EmailAttributes.descriptionLength)}
                           min={2}
                           max={500}
                           type="number"
                           name={EmailAttributes.descriptionLength}
                           valid={this.state.validate[EmailAttributes.descriptionLength] === 'valid'}
                           invalid={this.state.validate[EmailAttributes.descriptionLength] === 'invalid'}
                           onChange={(e) => {this.validateInput(e)
                             this.setCount(EmailAttributes.descriptionLength, e.target.valueAsNumber)}}/>
                </div>}
          </div>
        </div>
        <div className="block show-article-image">
          <div className="title">
            Show article images
          </div>
          <div className="description">
            Show images in your newsletter?
          </div>
          <input type="checkbox" className="checkbox"
                 checked={isTruthy(this.props.data[EmailAttributes.showNewsletterImages])}
                 onChange={(e) => this.setCount(EmailAttributes.showNewsletterImages, e.target.checked ? 1 : 0)}
                 id="show-article-image-checkbox"
          />
          <label htmlFor="show-article-image-checkbox">Show Images</label>
        </div>
        <div className="block read-more-text">
          <div className="title">
            Read more
          </div>
          <div className="url-rectangle-wrapper">
            <div className="url-left-element-text">
              TEXT
            </div>
            <div className={'url-right-element'}>
              <Input value={getAttribute(this.props.data, EmailAttributes.readMoreText) || ''}
                     onChange={(e) => this.props.onChange(EmailAttributes.readMoreText, e.target.value)}
                     placeholder={'Read more'}/>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  private validateInput = (e: any) => {
    const { validate } = this.state
    const minValue = Number(e.target.min) || 2
    const maxValue = Number(e.target.max) || 6
    if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
      (validate[e.target.name] = 'valid')
    } else {
      (validate[e.target.name] = 'invalid')
    }
    this.setState({validate})
  }
  private setCount = (attribute: EmailAttributes, newValue: number) => {
    // Placeholder - in case we need to do cross-validation of other numbers
    this.props.onChange(attribute, newValue)
    if (this.state.validate[EmailAttributes.minArticles] === 'invalid' ||
      this.state.validate[EmailAttributes.maxArticles] === 'invalid' ||
      this.state.validate[EmailAttributes.descriptionLength] === 'invalid') {
      this.props.setSectionValid(this.props.name, false)
    } else {
      this.props.setSectionValid(this.props.name, true)
    }
    this.setAlertMessage(newValue)
  }

  private setAlertMessage = (newValue: number) => {
    const templateModules = this.props.data.template_modules
    const maxArticleModuleCount = templateModules.reduce((acc, cur) => {
      if (cur.type === 'article') {
        if (cur.moduleConfig.count && cur.moduleConfig.count > acc) {
          return cur.moduleConfig.count
        }
      }
      return acc
    }, 0)
    if (newValue < maxArticleModuleCount) {
      this.setState({alertMessage: {
          maxCount: MAX_COUNT_BELOW_ARTICLE_MODULE_COUNT_MESSAGE,
        }})
    } else {
      this.setState({alertMessage: {
          maxCount: '',
        }})
    }

  }
}
