import { sizedArticleUrl } from 'app/utils'
import { getModuleConfigValue } from 'components/email-layout/utils'
import { Fade } from 'components/fade'
import { isTruthy } from 'generic/utility'
import React from 'react'
import { strfFormatDate } from 'shared_server_client/dates'
import { SectionConfig, TemplateModule } from 'shared_server_client/types/email_layout'
import {
  EmailAttributes, generalArticleAltText, headerAltText,
  headerLogoUrl,
  logoSizeAsNumber,
  ViewInBrowserFilter,
} from '../../constants'
import * as BaseLayout from '../base_layout'
import './styles.scss'

export class NewspaperLayout extends BaseLayout.BaseLayoutComponent {
  protected templateClassName: string = 'newspaper-layout'
  protected isWhiteFooter = (): boolean => true

  protected headerModule = () => {
    const { previewDate } = this.props
    const displayDate = !previewDate ? new Date() : previewDate
    return <div className="template-header">
      {this.getAttribute(EmailAttributes.viewInBrowser) &&
            this.getAttribute(EmailAttributes.viewInBrowser) === ViewInBrowserFilter.TOP &&
            this.renderViewInBrowserAtTop(EmailAttributes.secondaryColor)
      }
      <div className="date-and-subscribe-wrapper">
        <div className="date-and-subscribe text-uppercase"
            style={{borderBottom: `double ${this.getAttribute(EmailAttributes.primaryColor)}`}}>
          <div className="date"><p style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
          {strfFormatDate(displayDate, this.getAttribute(EmailAttributes.headerDateFormat))}</p></div>
          <div className="subscription">
            {(this.getAttribute(EmailAttributes.subscribeUrl) &&
              isTruthy(this.getAttribute(EmailAttributes.showSubscribeUrlInHeader))) &&
              <p style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
                {this.getAttribute(EmailAttributes.subscribeUrlText)}
              </p>}
            {(this.getAttribute(EmailAttributes.subscribeUrl) &&
              isTruthy(this.getAttribute(EmailAttributes.showUnsubscribeUrlInHeader))) &&
              <p className="unsubscribe-link" style={{color: this.getAttribute(EmailAttributes.secondaryColor)}}>
                {this.getAttribute(EmailAttributes.unsubscribeUrlText)}
              </p>}
          </div>
        </div>
      </div>
      {this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-image-wrapper-container">
        <div className="template-header-image-wrapper">
          <a href={this.props.session.brand_header_url} target="_blank" rel="noopener">
            <img className="template-header-image"
                src={headerLogoUrl(this.props.session)}
                alt={headerAltText(this.props.session)}
                style={{height: `${logoSizeAsNumber(this.props.session)}px`}}/>
          </a>
        </div>
      </div>}
      {!this.getAttribute(EmailAttributes.brandHeaderImageUrl) &&
      <div className="template-header-text-wrapper-container">
        <div className="template-header-text-wrapper"
            style={{width: 'auto', height: 'auto', borderTop: `1px solid ${this.getAttribute(EmailAttributes.secondaryColor)}`}}>
          <div className="template-header-text"
            style={{fontSize: this.getFontSize()}}>
            {this.getAttribute(EmailAttributes.companyName)}
          </div>
        </div>
      </div>}
    </div>
  }

  protected sectionModule = (module: TemplateModule) => {
    const articles = this.getSectionArticles(module)
    const section = this.getSectionDetails(module)
    //section will not be empty
    if ((articles && articles.length > 0) || section) {
      return <div className="article-module">
        {isTruthy(getModuleConfigValue(module, SectionConfig.SECTION_SHOW_HEADER, 'true')) &&
        <div className="section-title-wrapper">
          <span className="section-title" style={this.sectionTitleStyles(module)}>
            {section ?
             section.name : ''
            }
          </span>
        </div>}
        {articles && articles.length  ?
         <div>
          {module.isFirstArticleModule && articles.slice(module.moduleConfig.start, 1)
                                      .map((article, i) => (
            <FirstArticleHtml
              key={i}
              showImage={this.showImage}
              cappedDescription={this.cappedDescription}
              buildStyle={this.buildStyle}
              readMore={module.moduleConfig.readMore || ''}
              {...this.props}
              {...article}
            />
          ))}
          {articles.slice(module.moduleConfig.start + (module.isFirstArticleModule ? 1 : 0),
            module.moduleConfig.end || -1).map((article, i) => (
            <ArticleHtml
              key={i}
              showImage={this.showImage}
              cappedDescription={this.cappedDescription}
              buildStyle={this.buildStyle}
              readMore={module.moduleConfig.readMore || ''}
              {...this.props}
              {...article}
            />
          ))}
          </div>
          :
          <div className="no-article-section-module">
            There are no articles currently available for this section
          </div>
        }
      </div>
      } else {
        return null
      }
  }

  protected articleModule = (module: TemplateModule) => {
    if (!this.props.isLoading && this.props.content.articles.length === 0) {
      return <span className="no-content-message">You need more content for your next newsletter!</span>
    } else {
      return <div className="article-module">
        {module.isFirstArticleModule && this.props.content.articles.slice(module.moduleConfig.start, 1)
                                    .map((article, i) => (
          <FirstArticleHtml
            key={i}
            showImage={this.showImage}
            cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle} {...this.props} {...article}
          />
        ))}
        {this.props.content.articles.slice(module.moduleConfig.start + (module.isFirstArticleModule ? 1 : 0),
          module.moduleConfig.end).map((article, i) => (
          <ArticleHtml
            key={i}
            showImage={this.showImage}
            cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle} {...this.props} {...article}
          />
        ))}
      </div>
    }
  }

  protected restOfArticlesModule = (module: TemplateModule) => {
    const restOfArticles: any[] = (this.props.content.articles || []).slice(module.moduleConfig.start)
    if (restOfArticles.length) {
      return <div className="rest-of-articles-module">
        {restOfArticles.map((article, i) =>
          <ArticleHtml
            key={i}
            showImage={this.showImage}
            cappedDescription={this.cappedDescription}
            buildStyle={this.buildStyle}
            {...this.props}
            {...article}
          />,
        )}
      </div>
    } else {
      return null
    }
  }

  private buildStyle = (key: string, firstArticle: boolean) => {
    if (key === 'first-article-border') {
      return {
        borderBottom: `1px solid ${this.getAttribute(EmailAttributes.primaryColor)}`,
      }
    }
    const styleObject = {
      fontFamily: this.getAttribute(EmailAttributes.newsletterFontFamily) || 'Times',
      fontSize: '',
      fontWeight: this.getAttribute(`${key}_${EmailAttributes.fontWeight}`),
      color:  this.getAttribute(`${key}_${EmailAttributes.fontColor}`),
    }
    if (!firstArticle) {
      styleObject.fontFamily = this.getAttribute(EmailAttributes.newsletterFontFamily) || 'Times'
      styleObject.fontSize = this.getAttribute(`${key}_${EmailAttributes.fontSize}`)
    }
    return styleObject
  }
}

const ArticleHtml = (props: any) => <Fade>
  <div className="article-wrapper">
    <div className="article" style={props.buildStyle('first-article-border')}>
      {props.showImage(props, 170, 160) &&
      <div className="article-image">
        <a href={props.url} target="_blank" rel="noopener">
          <img src={sizedArticleUrl(props, 170, 160)}
               alt={generalArticleAltText(props.session)} height="170" width="160"/>
        </a>
      </div>}
      <div className="article-text-col">
        <div>
          <div className="source-title text-uppercase" style={props.buildStyle('source', false)}>
            <a href={props.url}
              style={props.buildStyle('source', false)}
              target="_blank" rel="noopener">
              {props.site_name || props.source_name}
            </a>
          </div>
        </div>
        <div>
        <a className="article-title"
             target="_blank"
             rel="noopener"
             style={props.buildStyle('title', false)}
             href={props.url}>
               {props.title}
          </a>
        </div>
        <div>
          <div className="article-content" style={props.buildStyle('description', false)}>
            <span dangerouslySetInnerHTML={{__html: props.cappedDescription(props.description)}}></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</Fade>

const FirstArticleHtml = (props: any) => <Fade>
  <div className="first-article-wrapper">
    {props.showImage(props, 447, 596) &&
    <div className="first-article-image">
      <a href={props.url} target="_blank" rel="noopener">
      <img src={sizedArticleUrl(props, 447, 596)}
           alt={generalArticleAltText(props.session)} height="447" width="596"/>
      </a>
    </div>}
    <div className="article" style={props.buildStyle('first-article-border')}>
      <div className="article-text-col">
        <div>
          <div className="source-title text-uppercase" style={props.buildStyle('source', true)}>
            <a href={props.url}
               style={props.buildStyle('source', true)}
               target="_blank"
               rel="noopener">
              {props.site_name || props.source_name}
            </a>
          </div>
        </div>
        <div>
        <div className="first-article-title" style={props.buildStyle('title', true)}>
            <a href={props.url}
               target="_blank"
               rel="noopener"
               style={props.buildStyle('title', true)}>
              {props.title}</a>
          </div>
        </div>
        <div>
          <div className="article-content" style={props.buildStyle('description', true)}>
            <span dangerouslySetInnerHTML={{__html: props.cappedDescription(props.description)}}></span>
          </div>
        </div>
        <div>
          <div className="read-more"
              style={{backgroundColor: props.session[EmailAttributes.titleFontColor]}}>
            <a href={props.url} target="_blank" rel="noopener">
              <p>{props.readMore || props.session[EmailAttributes.readMoreText]}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</Fade>
