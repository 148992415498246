// eslint-disable-next-line max-classes-per-file
import { sizedUrl} from 'app/utils'
import classnames from 'classnames'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import { Article } from 'components/icons/article'
import { EyeClosedIcon } from 'components/icons/eye-closed'
import { EyeOpenIcon } from 'components/icons/eye-open'
import { FrameLandscapeIcon } from 'components/icons/frame-landscape'
import { NewScheduledContentIcon } from 'components/icons/new-scheduled-content'
import { TextIcon } from 'components/icons/text-icon'
import pluralize from 'pluralize'
import * as React from 'react'
import { LEFT_SQUARE, RIGHT_SQUARE } from 'shared_server_client/constants'
import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import {
  getBannerImage,
  getBannerImageName,
  getSquareImageIndex,
  getSquareStaticImage,
} from 'shared_server_client/utils'
import {
  AllowedDeleteModules,
  EmailAttributes,
  getAttribute,
  IMAGE_REMOVED,
  MODULE_COUNT_ABOVE_MAX_ARTICLES_MESSAGE,
  StaticImageType,
} from '../constants'
import {
  getModuleIndex,
  getStaticImageAttribute,
  isClickableModule,
  isScheduledModuleById,
  } from '../utils'

const numberDropdownOptions = (max): DropdownOption[] => {
  return [
    ...Array(max).keys(),
  ].map((i) => ({
    description: `${(i + 1)}`,
    key: (i + 1),
    value: (i + 1),
  }))
}

enum StaticContentType {
  image = 'Image',
  text = 'Text',
  section = 'section',
}

export enum ModuleAction {
  clone = 'Clone',
  delete = 'Delete',
  edit = 'Edit',
  hide = 'Hide',
}

export interface ModulePropsChangeArgs {
  action: ModuleAction,
  module: TemplateModule,
  oldSequence?: number,
}

export interface TemplateModuleProps extends TemplateModule {
  choose: any,
  chooseLT2: any,
  onChange: any,
  onModulePropsChange: (change: ModulePropsChangeArgs) => void,
  select: any,
  showDelete?: boolean,
  staticImageTypes: StaticImageType[],
  templateModules: TemplateModule[],
  sectionSelect?: any,
}

export class TemplateModuleBase<P extends TemplateModuleProps = any, S = any> extends React.Component<any, any, any > {
  protected staticImageType: StaticImageType = null
  protected showEyeSwitch: boolean = false
  protected showScheduledContentFlag: boolean = false
  protected staticContentType: StaticContentType = null

  public render() {
    return <div className={`template-module ${this.props.type}`}>
      <div className="movable-icon-container" style={this.draggableIconContainerStyle()}>
        <i className="handle-icon fas fa-grip-lines icon-white" style={this.draggableIconStyle()} data-movable-handle />
      </div>
      {this.moduleHtml()}
    </div>
  }

  protected draggableIconContainerStyle = () => {
    return null
  }
  protected draggableIconStyle = () => {
    return null
  }

  protected moduleHtml() {
     return <div className="module-container">
      <div className={classnames('image-title-description', { 'clickable-item': isClickableModule(this.props.type) })}
          onClick={this.onClick}>
        {this.getIcon()}
        <div className="title-description">
          <div className="module-title">{this.getTitle()}</div>
        </div>
      </div>
      <div className="right-element-wrapper clickable-item">
        {this.showScheduledContentFlag && this.props.hasFeatureAccess
        ? <NewScheduledContentIcon
          svgwidth={33}
          svgheight={33}
          greyScale={!this.scheduledContentIsOn()}
          />
        : <div className="scheduled-content-icon-placeholder"/>}
        {this.showEyeSwitch &&
        <div className="right-element-sub-wrapper">
          <EyeSwitch
            isOpen={this.eyeIsOpen}
            onClick={this.eyeClick}
          />
        </div>
        }
        {this.getDeleteIcon()}
      </div>
    </div>
  }
  protected getDeleteIcon = () => {
    return  (this.props.showDelete || this.props.isManuallyAdded) && AllowedDeleteModules.includes(this.propsToTemplateModule().type) &&
      <div className="right-element-sub-wrapper delete-wrapper" onClick={this.deleteClick}>
        <i className="fas fa-trash"></i>
      </div>
  }
  protected getIcon = () => null
  protected getTitle = () => ''
  protected onClick = (ev) => null
  protected scheduledContentIsOn = (): boolean => false
  protected eyeIsOpen = (): boolean => false
  protected eyeClick = () => {
    this.toggleHide()
  }

  protected deleteClick = () => {
    const templateModule: TemplateModule = this.propsToTemplateModule()
    this.props.onModulePropsChange({
      action: ModuleAction.delete,
      module: {
        ...templateModule,
      },
    })
  }

  protected toggleHide = () => {
    const templateModule: TemplateModule = this.propsToTemplateModule()
    this.props.onModulePropsChange({
      action: ModuleAction.hide,
      module: {
        ...templateModule,
        hidden: !this.props.hidden,
      },
    })
  }

  protected propsToTemplateModule = (): TemplateModule => {
    return {
      hidden: this.props.hidden,
      moduleConfig: {
        ...this.props.moduleConfig,
      },
      sequence: this.props.sequence,
      type: this.props.type,
    }
  }
}

class HeaderModule extends TemplateModuleBase {
  protected getTitle = () => 'Header'
  protected getIcon = () => {
    const imageKey = 'brand_header_image_url'
    const url = getAttribute(this.props, imageKey)
    return <div className="image">{url ? <img src={sizedUrl(url, 50, 50)}/> : <FrameLandscapeIcon/>}</div>
  }
}

class LeadTextModule extends TemplateModuleBase {
  protected showEyeSwitch: boolean = true
  protected showScheduledContentFlag: boolean = true
  protected staticContentType: StaticContentType = StaticContentType.text

  protected getIcon = () => {
    return <div className="image">
        <TextIcon/>
      </div>
  }
  protected getTitle = () => `Text / HTML ${this.props.sequence}`

  protected onClick = (ev) => {
    const {hidden, moduleConfig, sequence, type} = this.props
    if (this.props.choose) {
      this.props.choose({ hidden, moduleConfig, sequence, type })
    }
  }

  protected eyeIsOpen = () => {
    const moduleIndex = getModuleIndex(
      this.props.template_modules,
      this.props.type,
      this.props.sequence,
    )
    const module = this.props.template_modules[moduleIndex]
    return !module.hidden
  }

  protected scheduledContentIsOn = (): boolean => {
    return isScheduledModuleById(this.props.template_modules, TemplateModuleType.leadText, this.props.sequence)
  }
}

class BannerImageModule extends TemplateModuleBase {
  protected showEyeSwitch: boolean = true
  protected showScheduledContentFlag: boolean = true

  protected staticContentType: StaticContentType = StaticContentType.image
  protected staticImageType = getBannerImage(this.props.staticImageTypes, this.props.sequence)

  protected moduleHtml() {
    this.staticImageType = getBannerImage(this.props.staticImageTypes, this.props.sequence)
    return super.moduleHtml()
  }

  protected getIcon = () => {
    const url = getStaticImageAttribute(this.props, this.staticImageType.id, 'hosted_image_url')
    return <div className="image">
            {this.scheduledContentIsOn()
            ? <FrameLandscapeIcon/>
            : url ? <img src={sizedUrl(url, 50, 50)} /> : <FrameLandscapeIcon/>}
          </div>
  }

  protected getTitle = () => {
    return getBannerImageName(this.props.sequence)
  }

  protected onClick = (ev) => {
    if (this.props.select) {
      const {hidden, moduleConfig, sequence, type} = this.props
      this.props.select({...this.staticImageType, hidden, moduleConfig, sequence, type})
    }
  }

  protected scheduledContentIsOn = () => {
    return isScheduledModuleById(this.props.template_modules, TemplateModuleType.image, this.props.sequence)
  }

  protected eyeIsOpen = () => {
    const moduleIndex = getModuleIndex(
      this.props.template_modules,
      this.props.type,
      this.props.sequence,
    )

    const module = this.props.template_modules[moduleIndex]
    return !module.hidden
  }

}

class TwoColumnImageModule extends TemplateModuleBase {
  protected showEyeSwitch: boolean = true
  protected showScheduledContentFlag: boolean = true
  // TODO: not sure we ever support clonning of this.
  //But if we do we need to figure out correct indexes for static images in database.
  protected leftImage = getSquareStaticImage(this.props.staticImageTypes, this.props.sequence, LEFT_SQUARE)
  protected rightImage = getSquareStaticImage(this.props.staticImageTypes, this.props.sequence, RIGHT_SQUARE)

  public render() {
    return <div className={`template-module-wrapper ${this.props.type}`}>
      <div className="left-container template-module">
        <div className="movable-icon-container" style={this.draggableIconContainerStyle()}>
          <i className="handle-icon fas fa-grip-lines icon-white"
            style={this.draggableIconStyle()} data-movable-handle />
        </div>
        <div className="image-title-description clickable-item" onClick={this.onClick}>
          <div>{this.getIcon()}</div>
          <div className="title-description">
            <div className="module-title">{this.getTitle()}</div>
          </div>
        </div>
      </div>
      <div className="bridge">--</div>
      <div className="right-container template-module">
        <div className="image-title-description clickable-item" onClick={this.onClick2}>
          <div>{this.getIcon2()}</div>
        </div>
        <div className="right-element-wrapper">
          {this.props.hasFeatureAccess
          ? <NewScheduledContentIcon svgwidth={33} svgheight={33} greyScale={!this.scheduledContentIsOn()}/>
          : <div className="scheduled-content-icon-placeholder"/>}
          <EyeSwitch
            isOpen={this.eyeIsOpen}
            onClick={this.eyeClick}
          />
          {this.getDeleteIcon()}
        </div>
      </div>
    </div>
  }

  protected getIcon = () => {
    const url = getStaticImageAttribute(this.props, this.leftImage.id, 'hosted_image_url')
    return <div className="image">
      {this.scheduledContentIsOn()
      ? <FrameLandscapeIcon/>
      : url
      ? <img src={sizedUrl(url, 50, 50)}/>
      : <FrameLandscapeIcon/>}</div>
  }

  protected getIcon2 = () => {
    const url = getStaticImageAttribute(this.props, this.rightImage.id, 'hosted_image_url')
    return <div className="image">
      {this.scheduledContentIsOn()
      ? <FrameLandscapeIcon/>
      : url
      ? <img src={sizedUrl(url, 50, 50)}/>
      : <FrameLandscapeIcon/>}</div>
  }

  protected getTitle = () => {
    const leftIndex = getSquareImageIndex(this.props.sequence, LEFT_SQUARE)
    const rightIndex = getSquareImageIndex(this.props.sequence, RIGHT_SQUARE)
    return `Squares ${leftIndex} & ${rightIndex}`
  }

  protected onClick = (ev) => {
    if (this.props.select) {
      const {hidden, moduleConfig, sequence, type} = this.props
      this.props.select({...this.leftImage, hidden, moduleConfig, sequence, type})
    }
  }

  protected onClick2 = (ev) => {
    if (this.props.select) {
      const {hidden, moduleConfig, sequence, type} = this.props
      this.props.select({...this.rightImage, hidden, moduleConfig, sequence, type})
    }
  }

  protected eyeIsOpen = () => {
    const moduleIndex = getModuleIndex(
      this.props.template_modules,
      this.props.type,
      this.props.sequence,
    )

    const module = this.props.template_modules[moduleIndex]
    return !module.hidden
  }

  protected eyeClick = () => {
    this.toggleHide()
  }

  protected scheduledContentIsOn = () => {
    return isScheduledModuleById(this.props.template_modules, TemplateModuleType.twoColumnImage, this.props.sequence)
  }

}

class SponsoredModule extends TemplateModuleBase {
  constructor(props: any) {
    super(props)
  }
  protected moduleHtml() {
    return <div className="module-container">
      <div className="image-title-description clickable-item">
        {this.getIcon()}
        <div className="title-description">
          <div className="module-title">{this.getTitle()}</div>
        </div>
      </div>
      <div className="right-element-wrapper clickable-item">
        {this.getDeleteIcon()}
      </div>
    </div>
  }
  protected getTitle = () => {
    const count = this.props.sequence || 1
    return `${'Sponsored'} ${count}`
  }
  protected getIcon = () => {
    return <div className="image"><Article svgwidth={50} svgheight={50}/></div>
  }
  protected getModuleLabel = (): string => 'Sponsored'
}

interface ArticleState {
  editedCountValue: number,
  isEditing: boolean,
}
class ArticleModule extends TemplateModuleBase<TemplateModuleProps, ArticleState> {
  constructor(props: any) {
    super(props)

    this.state = {
      editedCountValue: this.props.moduleConfig.count,
      isEditing: false,
    }
  }

  protected moduleHtml() {
    return <div className="module-container">
      <div className="image-title-description clickable-item" onClick={this.onClick}>
        {this.getIcon()}
        <div className="title-description">
          {this.state.isEditing
          ? <div className="module-edit-wrapper">
              <div className="module-title-edit-wrapper">
                <span className="module-title">Count:</span>
                <DropdownComponent
                  data={numberDropdownOptions(10)}
                  selected={this.state.editedCountValue}
                  onChange={this.onDropdownChange}
                />
              </div>
              <div className="save-cancel">
                <div className="action-icon icon-save" title="Save" onClick={this.onSave}>
                  <i className="fas fa-check" />
                </div>
                <div className="action-icon icon-cancel" title="Cancel" onClick={this.onCancel}>
                  <i className="fas fa-window-close" />
                </div>
              </div>
            </div>
          : <div className="module-title">{this.getTitle()}</div>
          }
        </div>
      </div>
      <div className="right-element-wrapper clickable-item">
        {this.getDeleteIcon()}
      </div>
      {this.displayAlert()}
    </div>
  }

  protected getTitle = () => {
    const count = this.props.moduleConfig.count
    return `${count} ${pluralize('Article', count)}`
  }

  protected getIcon = () => {
    return <div className="image"><Article svgwidth={50} svgheight={50}/></div>
  }

  protected getModuleLabel = (): string => 'Article'

  protected onClick = (ev) => {
    if (!this.state.isEditing) {
      this.setState({
        isEditing: true,
        editedCountValue: this.props.moduleConfig.count,
      })
    }
  }

  protected onDropdownChange = (ev) => {
    const newValue = Math.min(parseInt(ev.selected.value, 10), this.maxArticles())
    this.setState({editedCountValue: newValue})
  }

  private maxArticles = () => {
    return parseInt(getAttribute(this.props, EmailAttributes.maxArticles), 10)
  }

  private handleCountChange = (count: number) => {
    const templateModule: TemplateModule = this.propsToTemplateModule()
    templateModule.moduleConfig.count = count

    this.props.onModulePropsChange({
      action: ModuleAction.edit,
      module: templateModule,
    })
  }

  private onSave = (e) => {
    e.stopPropagation()
    this.setState({isEditing: false}, () => {
      this.handleCountChange(this.state.editedCountValue)
    })
  }
  private onCancel = (e) => {
    e.stopPropagation()
    this.setState({isEditing: false, editedCountValue: this.props.moduleConfig.count})
  }

  private displayAlert = () => {
    if (this.props.type === 'article' && !this.state.isEditing) {
      if (this.props.moduleConfig.count > this.props[EmailAttributes.maxArticles]) {
        return <div className="alert-message">{MODULE_COUNT_ABOVE_MAX_ARTICLES_MESSAGE}</div>
      } else {
        return null
      }
    }
  }
}

class RestOfArticlesModule extends TemplateModuleBase {
  protected getTitle = () => 'Rest of Articles'
  protected getIcon = () => {
    return <div className="image"><Article svgwidth={50} svgheight={50}/></div>
  }
}

class SectionModule extends TemplateModuleBase {
  //protected showEyeSwitch: boolean = true
  //protected showScheduledContentFlag: boolean = true
  protected staticContentType: StaticContentType = StaticContentType.section

  protected getTitle = () => {
    const section = this.props.sections.find((x) => x.id === this.props.moduleConfig.sectionId) || {name: 'Article'}
    const count = this.props.moduleConfig.count
    if ( count ) {
      return `${count} ${section.name}`
    } else {
      return section.name
    }
  }

  protected onDropdownChange = (ev) => {
    this.setState({editedCountValue: parseInt(ev.selected.value, 10)})
  }

  protected onClick = (ev) => {
    if (this.props.sectionSelect) {
      const {hidden, moduleConfig, sequence, type} = this.props
      this.props.sectionSelect({hidden, moduleConfig, sequence, type})
    }
  }

protected eyeIsOpen = () => {
    const moduleIndex = getModuleIndex(
      this.props.template_modules,
      this.props.type,
      this.props.sequence,
    )

    const module = this.props.template_modules[moduleIndex]
    return !module.hidden
  }

  protected eyeClick = () => {
    this.toggleHide()
  }

  protected scheduledContentIsOn = (): boolean => {
    return isScheduledModuleById(this.props.template_modules, TemplateModuleType.section, this.props.sequence)
  }
}

class FooterModule extends TemplateModuleBase {
  public render() {
    return <div className={`template-module ${this.props.type}`}>
      <div className="movable-icon-container"></div>
      {this.moduleHtml()}
    </div>
  }

  protected getTitle = () => 'Footer'
  protected getIcon = () => {
    const imageKey = 'brand_footer_image_url'
    const url = getAttribute(this.props, imageKey)
    return <div className="image">{(url && url !== IMAGE_REMOVED) ? <img src={sizedUrl(url, 50, 50)}/>
      : <FrameLandscapeIcon/>}</div>
  }
}

interface EyeSwitchProps {
  onClick: any,
  isOpen: any,
}

const EyeSwitch = (props: EyeSwitchProps) => {
  return <div className="eye-icon-wrapper clickable-item" onClick={() => props.onClick()}>
    {props.isOpen() ? <EyeOpenIcon/> : <EyeClosedIcon/>}
  </div>
}

export const RenderDesignModules = (moduleProps: TemplateModuleProps) => {
  if (moduleProps.type === TemplateModuleType.header) {
    return <HeaderModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.leadText) {
    return <LeadTextModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.image) {
    return <BannerImageModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.article) {
    return <ArticleModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.twoColumnImage) {
    return <TwoColumnImageModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.restOfArticles) {
    return <RestOfArticlesModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.section) {
    return <SectionModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.sponsored) {
    return <SponsoredModule {...moduleProps} />
  } else if (moduleProps.type === TemplateModuleType.footer) {
    return <FooterModule {...moduleProps} />
  }
}
